<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card class="mt-3" header="Profile">
          <b-form>
            <b-row>
              <b-col>
                <b-form-group id="input-group-2" label="Name:" label-for="i1">
                  <b-form-input id="i1" v-model="user.name" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Email:" label-for="i2">
                  <b-form-input id="i2" v-model="user.email" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Function:" label-for="i3">
                  <b-form-input id="i3" v-model="user.function" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import OrderInfo from "@/view/content/OrderInfo.vue";

export default {
  name: "profile",
  methods: {},
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    var id = localStorage.getItem("id");

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readAdmin&id=" + id)
      .then(res => res.json())
      .then(data => {
        this.user = data.user[0];
      })
      .catch(console.log);
  }
};
</script>
